
import "./FormField.scss";
function FormField({ type, id, value, placeholder, onChange, c, r, onFocus, onBlur }) {

  return (
    <div className={`form-group`}>
      {c > 0 && r > 0 ? (
        <textarea className="form-field"
        type={type}
        id={id}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        cols={c}
        rows={r} />
      ) : (
        <input
          className="form-field"
          type={type}
          id={id}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          autoComplete= "off"
        />
      )}
    </div>
  );
}

export default FormField;
