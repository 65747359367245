import "./ComparisonChartStatic.scss";
import { useState, React, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as DividerLine } from "../../assets/icons/divider.svg";
import requirementsPositive from "../../assets/icons/mdp-requirements-yes.svg";
import requirementsNegative from "../../assets/icons/mdp-requirements-no.svg";
import recommendedIcon from "../../assets/icons/mdp-recommended.svg";
import discountedIcon from "../../assets/icons/discount.png"
import ComparisonChartCompatibilitySection from "../ComparisonChartCompatibilitySection/ComparisonChartCompatibilitySection.jsx";
import { useNavigate } from "react-router-dom";
import {ReactComponent as MaeCoreLogoBlack} from '../../assets/logos/MaeCoreLogoBlack.svg'
import { useDealershipContext } from "../../contexts/dealershipContext";
import { LIFESTYLE_CONFIG } from "../../config/lifestyleConfig.js";
import { CCF_ID } from '../../constants/dealershipIds';
import { ANRQuizIndexMapping, CCFQuizIndexMappingV0, CCFQuizIndexMappingV1 } from '../../data/dealershipQuiz/quizIndexMapping';

function CheckIcon({ check }) {
  return (
    <div className="as-requirement-container">
      <img
        className="as-requirement_icon"
        src={check ? requirementsPositive : requirementsNegative}
        alt=""
      />
    </div>
  );
}

export default function ComparisonChartStatic({targetDivRef, carsToCompare, name, quiz, carsToCompareIndexes, matchesPage}) {
  
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [userId, setUserId] = useState(null);
  const [source, setSource] = useState("site");
  // const { resetAutoshowContext } = useAutoshowContext();
  const [loading, setLoading] = useState(true);
  const [userSelectedFeatures, setUserSelectedFeatures] = useState([]);
  const [carsToCompareList, setCarsToCompareList] = useState(carsToCompare.slice(0,3));
  const [exclusiveCtaPopup, setExclusiveCtaPopup] = useState(false);
  const [FeatureCtaPopup, setFeatureCtaPopup] = useState(false);
  const [popupOpened, setPopupOpened] = useState(false);
  const [bFlag, setBFlag] = useState(false);
  const [dtgcFlag, setDtgcFlag] = useState(false);
  const [bdtgcFlag, setBdtgcFlag] = useState(false);
  const {dealershipName,dealershipId,linkMatchesToVDP,dealershipImgUrl,defaultInventoryImage} = useDealershipContext();
  // const [customizationPopupToggle, setCustomizationPopupToggle] =
  //   useState(false);
  const [featurePopupToggle, setFeaturePopupToggle] = useState(false);
  const [addMoreCarsPopup, setAddMoreCarsPopup] = useState(false);
  const [userFlowChoice, setUserFlowChoice] = useState("");

  //backward compatibility with the old quiz for ccf
  let quizIndexMapping = dealershipId === CCF_ID ? quiz.length > 14 ? CCFQuizIndexMappingV0 : CCFQuizIndexMappingV1 : ANRQuizIndexMapping; 
  const lifestyle = dealershipId === CCF_ID ? quiz[quizIndexMapping.LIFESTYLE_INDEX].selectedOptions : [];

  const bFlagText = "We had to widened our search by adjusting the budget! ";

  const dtgcText =
    "We had to widened our search and found vehicles that are powered similarly!";

  const combinedText =
    "We had to widen our search and found similar vehicle types with the closest price. ";

  const submittedPopupText =
    "Thanks! A car buying bestie from Mae will be in touch! ";

  useEffect(() => {
    if(!matchesPage && carsToCompareIndexes.length === 0) {
      setCarsToCompareList(carsToCompare.slice(0,3));
      return;
    }
    if(!carsToCompareIndexes || carsToCompareIndexes.length < 0) return;
    const currCompareList = [];
    carsToCompareIndexes.forEach((val,_) => {
      if(carsToCompare[val] && currCompareList.length < 3) {
        currCompareList.push(carsToCompare[val]);
      } else if(currCompareList.length === 3) {
        currCompareList.splice(0,1);
        currCompareList.push(carsToCompare[val]);
      }
    })
    setCarsToCompareList(currCompareList);
  },[carsToCompareIndexes])

  // async function createComparisonChartInfo() {
  //   const sourceVar = params.get("generated");

  //   setSource(sourceVar);

  //   setUserId(params.get("userId"));

  //   setUserFlowChoice(params.get("flow"));

  //   let bFlagVar = params.get("bFlag") === "true" ? true : false;
  //   let dtgcFlagVar = params.get("dtgcFlag") === "true" ? true : false;

  //   setBFlag(bFlagVar);

  //   setDtgcFlag(dtgcFlagVar);

  //   setBdtgcFlag(bFlagVar && dtgcFlagVar);

  //   const maeTrimIds = [];

  //   for (let i = 1; i <= 14; i++) {
  //     const feature = params.get(`feature${i}`);
  //     const maeTrimId = params.get(`maeTrimId_${i}`);
  //     if (
  //       typeof feature === "string" &&
  //       !userSelectedFeatures.includes(feature)
  //     )
  //       userSelectedFeatures.push(feature);
  //     if (!maeTrimIds.includes(maeTrimId) && i <= 2) maeTrimIds.push(maeTrimId);
  //   }

  //   try {
  //     const response = await fetch("/api/autoshow/getmdpcarsbyid", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //       body: JSON.stringify({
  //         maeTrimIds: maeTrimIds,
  //         userSelectedFeatures: userSelectedFeatures,
  //       }),
  //       credentials: "include",
  //     });

  //     if (response.ok) {
  //       const res = await response.json();
  //       console.log(res);
  //       setCarsToCompareList(res.carsInfo);
  //     } else {
  //       const res = await response.json();
  //       console.log("Error : ", res.error);
  //     }
  //   } catch (error) {
  //     console.log("Error : ", error);
  //   }

  //   setUserSelectedFeatures(userSelectedFeatures);
  //   setLoading(false);
  // }

  // const onRestartButton = () => {
  //   console.log("restart clicked");
  //   resetAutoshowContext();
  //   navigate("/");
  //   window.location.reload();
  // };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   createComparisonChartInfo();

  //   return () => {
  //     setCarsToCompareList([]);
  //     setUserSelectedFeatures([]);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (source === "mail")
  //     trackAutoshowUser({ openedChartThroughEmail: true }, userId);
  // }, [source]);

  // useEffect(() => {
  //   if (popupOpened) {
  //     document.body.classList.add("no-scroll");
  //   } else {
  //     document.body.classList.remove("no-scroll");
  //   }
  // }, [popupOpened]);

  // function exclusiveCtaClicked() {
  //   window.scrollTo(0, 0);
  //   trackAutoshowUser({ clickedOnExclusiveDeals: true }, userId);
  //   setPopupOpened(true);
  //   setExclusiveCtaPopup(true);
  // }

  // function featureCtaClicked() {
  //   window.scrollTo(0, 0);
  //   trackAutoshowUser({ clickedOnAddingMoreFeatures: true }, userId);
  //   setPopupOpened(true);
  //   setFeatureCtaPopup(true);
  // }

  function formatPrice(value) {
    return new Intl.NumberFormat("en-US").format(value);
  }

  function formatCapitalisation(str) {
    return str
      .replace(/([A-Z])/g, " $1")
      .trim()
      .replace(/^./, (match) => match.toUpperCase());
  }

  // if (loading) {
  //   return <LoadingScreen />;
  // }

  return (
    <>
      <div ref={targetDivRef}>
      {!matchesPage && <img src={dealershipImgUrl} alt ="" style={{width:"180px"}}/>}
      <div className="product_info__creator_section" style={{alignItems:"center", paddingTop:"0.5rem 0"}}>
      <h2 style={{margin:"0"}}> {name}'s Comparison Chart</h2>
        <div className="product_info__text" style={{fontWeight : "400",width:"auto"}}>Powered By</div>
        <div className="product_info__logo">
          <MaeCoreLogoBlack/>
        </div>
      </div>
      </div>
      {/* {exclusiveCtaPopup ? (
        <CustomizationPopup
          togglePopup={setExclusiveCtaPopup}
          userId={userId}
          popupOpenedfn={setPopupOpened}
          submittedPopup={setCustomizationPopupToggle}
        />
      ) : null}
      {FeatureCtaPopup ? (
        <FeaturePopup
          togglePopup={setFeatureCtaPopup}
          userId={userId}
          popupOpenedfn={setPopupOpened}
          submittedPopup={setFeaturePopupToggle}
        />
      ) : null}

      {addMoreCarsPopup ? (
        <FeaturePopup
          togglePopup={setAddMoreCarsPopup}
          userId={userId}
          popupOpenedfn={setPopupOpened}
          submittedPopup={setFeaturePopupToggle}
        />
      ) : null} */}

      {/* <div className="popup_position">
        {dtgcFlag && bFlag && bdtgcFlag ? (
          <UpdatePopup Popupshow={setBdtgcFlag} main={combinedText} />
        ) : null}
      </div> */}
      {/* <div className="popup_position">
        {bFlag && !dtgcFlag ? (
          <UpdatePopup Popupshow={setBFlag} main={bFlagText} />
        ) : null}
      </div>
      <div className="popup_position">
        {dtgcFlag && !bFlag ? (
          <UpdatePopup Popupshow={setDtgcFlag} main={dtgcText} />
        ) : null}
      </div>
      <div className="popup_position">
        {customizationPopupToggle ? (
          <UpdatePopup
            Popupshow={setCustomizationPopupToggle}
            main={submittedPopupText}
          />
        ) : null}
      </div> */}
      {/* <div className="popup_position">
        {featurePopupToggle ? (
          <UpdatePopup
            Popupshow={setFeaturePopupToggle}
            main={submittedPopupText}
          />
        ) : null}
      </div> */}
      <div className={`as-comparechart ${popupOpened ? "blured" : ""}`}>

          {/* <div className="as-comparechart__restart" >
            <span className="as-comparechart__restart--button">
              <RestartButton />
            </span>
            <p className="as-comparechart__restart--text">Restart</p>
          </div> */}
        {/* <div className="as-comparechart__offer-container">
          <p className="as-comparechart__offer-container--text">
            Get Exclusive deals and offers on these vehicles from Mae's
            hand-picked and trusted dealers near you.
          </p>
          <div
            className="as-comparechart__offer-container--button"
          >
            <ButtonGradient text="Buy with Mae" />
          </div>
        </div> */}
        <div className="as-comparechart__container">
          {/* Car name */}
          <div className="chart-container">
            <h3 className="chart-container__name-text"></h3>
            {carsToCompareList.map((vehicle, index) => (
              <div className="chart-container__container" key={vehicle._id} style={{display:"flex", flexDirection:"column"}}>
                <h3 key={index} className="chart-container__name-text">
                  {`${vehicle.year} ${vehicle.make} ${vehicle.model} (${vehicle.newOrUsed})`}
                </h3>
                <h5>#{vehicle.stockNumber}</h5>
              </div>
            ))}
          </div>

          {/* Image section of the car */}
          <div className="chart-container">
            <p className="chart-container__add_car_sign">
              {/* <AddCarSign
                onClick={() => {
                  trackAutoshowUser({ clickedOnAddMoreCars: true }, userId);
                  setAddMoreCarsPopup(true);
                  setPopupOpened(true);
                }}
              /> */}
            </p>
            {carsToCompareList.map((vehicle, index) => (
              <div className="chart-container__container" key={index}>
                <div className="img-block">
                  <img
                    className="img-block--img"
                    src={vehicle.imageLink}
                    alt = ""
                  />
                </div>
              </div>
            ))}
          </div>

            {/* Recommended Trim for the car */}
            <div className="chart-container">
            <div className="label-with-info" style={{justifyContent : "flex-start"}}>
              <p className="chart-container__label-text"  >Recommended Trim 
               {/* <Info className="chart-container__label-text__translate-y"/> */}
               
              </p>
              
              {/* <div className="tooltip tooltip1">Lowest trim that gives you what you want and the most value</div> */}
            </div>
            {carsToCompareList.map((vehicle, index) => (
              <div key={index} className="recommended-trim-block">
                <img
                  src={recommendedIcon}
                  alt="Recommended"
                  className="recommended-trim-block--icon"
                />
                <h4 className="recommended-trim-block--text">
                  {vehicle.trim}
                </h4>
              </div>
            ))}
          </div>

          {/* Price section of the car */}
          <div className="chart-container">
          <div className="label-with-info" style={{justifyContent : "flex-start"}}>
            <p className="chart-container__label-text" > MSRP
                {/* <Info /> */}
                </p>
                {/* <div className="tooltip tooltip6">This does not include taxes, dealer fees, extra colours, shipping costs etc.</div> */}
                </div>
            {carsToCompareList.map((vehicle, index) => (
              <div key={index} className="price-block">
                <p className="price-block--currency">$</p>
                {formatPrice(vehicle.baseMSRP)}
                {vehicle.discountedFlag?<img className="liked-card__details--discount" alt="" src={discountedIcon}></img>:null}
              </div>
            ))}
            </div>
          

          <div className="as-divider-line">
            <DividerLine />
          </div>

          {/* Compatibility for the cars */}
          <div className="chart-container">
            <div className="label-with-info" style={{justifyContent : "flex-start"}}>
            <p className="chart-container__label-text" >Compatibility
                {/* <Info /> */}
                </p>
              {/* <div className="tooltip tooltip5">We calculate our compatibility through a combination of vehicle information prioritising price and features cause we want to get you the most value for least amount of money</div> */}
            </div>
            
            
            {carsToCompareList.map((vehicle, index) => (
              <div className="match-block" key={index}>
                <ComparisonChartCompatibilitySection
                  key={index}
                  vehicle={vehicle}
                  matchedFeaturesLength={vehicle.matchedFeatures.length}
                  totalFeaturesLength={vehicle.totalFeatures.length}
                  flow={userFlowChoice}
                />
              </div>
            ))}
          </div>

          <div className="as-divider-line">
            <DividerLine />
          </div>

          {/* Requirement for the car */}
          <div className="chart-container">
            <div className="compare-feature-block">
              <p className="chart-container__label-text">Engine Power Type</p>
              <p className="chart-container__label-text">Drive Type</p>
            </div>
            {carsToCompareList.map((vehicle, index) => (
              <div key={index} className="compare-feature-block">
                <div className="label-with-info">
                  <div className="compare-feature-block__text">
                    {vehicle.engine}
                  </div>
                  {/* {vehicle.calculatedMDPFeatures.engine.map((powerType, i) => (
                    (powerType === "HEV" || powerType ==="PHEV") && (<><Info onMouseEnter={() => {
                      const tooltip = document.querySelector(`.tooltip${index+5}`);
                      tooltip.style.visibility = 'visible';
                      tooltip.style.opacity = '1';
                    }}
                    onMouseLeave={() => {
                      const tooltip = document.querySelector(`.tooltip${index+5}`);
                      tooltip.style.visibility = 'hidden';
                      tooltip.style.opacity = '0';
                    }} />
                   <div className={`tooltip tooltip${index+5}`} style={{width : "fit-content"}}>
                        {powerType === "PHEV" && "PHEV : Plug-In Hybrid Gasoline Engine"}
                        {powerType === "HEV" && "HEV : Hybrid Gasoline Engine"}
                  </div></>
                  )))} */}
              </div>
                
                <div className="label-with-info">
                  <div className="compare-feature-block__text">
                    {vehicle.driveTrain} 
                  </div>
                 
                  {/* <div className={`tooltip tooltip${index+2}`} style={{width : "fit-content"}}>
                    {vehicle.vehicleInfo.driveType === "AWD" ? "All Wheel Drive" : vehicle.vehicleInfo.driveType === "FWD" ? "Forward Wheel Drive" : "4 Wheel Drive"}
                  </div> */}
               </div>
              </div>
            ))}
          </div>

          <div className="as-divider-line">
            <DividerLine />
          </div>

          {/* Your desired features for the car */}
          <div className="chart-container">
            <div className="compare-feature-block">
              <p className="chart-container__label-text-group">
                Your Desired Features
              </p>
              {carsToCompareList[0]?.totalFeatures.map((feature, index) => (
                <div className="compare-feature-block__inner" key={index}>
                  <p key={index} className="chart-container__label-text">
                    {formatCapitalisation(feature)}
                  </p>
                  {carsToCompareList.map((vehicle, index) => {
                    return vehicle.maeFeatures[feature] === true ? (
                      <CheckIcon check={true} key={index} />
                    ) : (
                      <CheckIcon check={false} key={index}/>
                    );
                  })}
                </div>
              ))}
              {/* <span className="more-features" >
                <span className="more-features__add">
                  <AddFeaturesButton />
                </span>
                <span className="more-features__text">Add more Features</span>
              </span> */}
            </div>
          </div>

          <div className="as-divider-line">
            <DividerLine />
          </div>
          
          {/* Lifestyle section for the car */}
         
          {
            lifestyle.map((lifestyleOption,index) => {
              const lifestyleFeatures = LIFESTYLE_CONFIG[lifestyleOption];

              if (!lifestyleFeatures) {
                return null
              }

              return (<div className="chart-container">
              <div className="compare-feature-block"> 
                  <p className="chart-container__label-text-group">
                {lifestyleOption}
                </p>
                {Object.keys(lifestyleFeatures).map(feature => (
                <div className="compare-feature-block__inner" key={feature}>
                  <p key={index} className="chart-container__label-text">
                    {lifestyleFeatures[feature].text}
                  </p>
                {carsToCompareList.map((vehicle, index) => {
                  let featureValue = vehicle.maeFeatures[feature]
                  if (lifestyleFeatures[feature].location == "not in maefeatures"){
                    featureValue = vehicle[feature]
                  }

                  if (lifestyleFeatures[feature].type == "value"){
                    return  <div className="label-with-info" key={index}>
                    <div className="compare-feature-block__text">
                      {featureValue}
                    </div>
                    </div>
                  }
                  else {
                    return featureValue === true ? (
                      <CheckIcon check={true} key={index} />
                    ) : (
                      <CheckIcon check={false} key={index}/>
                    );
                  }
                })}
              </div>))}
              </div>
              </div>)
            })
          }

          {/* Your other features for the car */}
          {/* <div className="chart-container">
            <div className="compare-feature-block">
              <p className="chart-container__label-text-group">
                Other Features
              </p>
              {Object.keys(
                carsToCompareList[0]
                  ? carsToCompareList[0]?.maeFeatures
                  : {}
              ).map((feature,index) => (
                <div key={index}>
                  {!carsToCompareList[0]?.totalFeatures.includes(feature) &&
                    feature !== "_id" && (
                      <div key={index}>
                        <div className="compare-feature-block__inner">
                          <p className="chart-container__label-text">
                            {formatCapitalisation(feature)}
                          </p>
                          {carsToCompareList.map((vehicle) => {
                            return vehicle.maeFeatures[feature] ===
                              true ? (
                              <CheckIcon check={true} key={`${vehicle._id}-${index}`}/>
                            ) : (
                              <CheckIcon check={false} key={`${vehicle._id}-${index}`}/>
                            );
                          })}
                        </div>
                      </div>
                    )}
                </div>
              ))}
            </div>
          </div> */}
      
        </div>
      </div>
    </>
  );
}