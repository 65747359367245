import { produce } from "immer";
import FormFieldWithLabel from "../FormFieldWithLabel/FormFieldWithLabel";

export default function QIConditionalInput({ currentQuestionViewIndex, childIndex, quizQuestions, setQuizQuestions }) {

  function editInputValue(text) {
    setQuizQuestions(
      produce((quizQuestionsDraft) => {
        const currentQuestionDraft = quizQuestionsDraft[currentQuestionViewIndex].children[childIndex];
        currentQuestionDraft.inputValue = text;
    }));
  }

  return (
    <div className="container">
      <FormFieldWithLabel
          label= {quizQuestions[currentQuestionViewIndex].children[childIndex].inputLabel}
          type= "text"
          id= {quizQuestions[currentQuestionViewIndex].children[childIndex].inputLabel}
          placeholder=""
          value={quizQuestions[currentQuestionViewIndex].children[childIndex].inputValue}
          onChange={(e) => {
            editInputValue(e.target.value)
          }}
          errorMessage={""}
        />
        
    </div>
  );
}