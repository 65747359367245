import "./FormFieldWithLabel.scss";
import { ReactComponent as ErrorSVG } from "../../assets/images/error.svg";
import FormField from "../FormField/FormField";

export default function FormFieldWithLabel({
  label,
  type,
  id,
  placeholder,
  value,
  onChange,
  errorMessage,
  onFocus,
  onBlur
}) {
  return (
    <div className="form_field_with_label">
      <div className="form_field_with_label__container">
        <span className="form_field_with_label__text">{label}</span>
        <div className="error_message_container">
          {errorMessage ? <ErrorSVG /> : null}
          <span className="error_message_container__text">
            {errorMessage}
          </span>
        </div>
      </div>
      <FormField
        label={label}
        type={type}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
}
