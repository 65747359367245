import { ANR_ID, CCF_ID } from "../constants/dealershipIds";

export const DEALERSHIP_CONFIG = {
    [CCF_ID]:{
        name: "Cam Clark Ford",
        imgUrl: "https://ddztmb1ahc6o7.cloudfront.net/camclarkfordrichmond/wp-content/uploads/2024/04/12160321/Cam-Clark-Ford-PNG.png",
        linkMatchesToVDP: true,
        defaultInventoryImage: false,
        // salesPeople: {
        //     0:{name:"",email:""},
        //     1:{name:"Dennis Liu", email:"hliu@camclarkford.com"},
        //     2:{name:"Chris Yu", email:"cyu@camclarkford.com"},
        //     3:{name:"Daniel Chiu" ,email:"dchiu@camclarkford.com"},
        //     4:{name:"Lawrence De Leon" ,email:"ldeleon@camclarkford.com"},
        //     5:{name:"Jeffrey Ng" ,email:"jng@camclarkford.com"},
        //     6:{name:"Dev Bhardwaj" ,email:"dbhardwaj@camclarkford.com"},
        //     7:{name:"Nicole Bullock" ,email:"nbullock@camclarkford.com"},
        //     8:{name:"Emre Celik" ,email:"ecelik@camclarkford.com"}
        // },
        salesPeople: {
            0:{name:"",email:""},
            1:{name:"sp1 Dennis Liu", email:"sp1@makingautoeasy.com"},
            2:{name:"sp2 Chris Yu",email:"sp2@makingautoeasy.com"},
            3:{name:"sp3 Daniel Chiu" ,email:"sp3@makingautoeasy.com"},
            4:{name:"sp4 Lawrence De Leon" ,email:"sp4@makingautoeasy.com"},
            5:{name:"sp5 Jeffrey Ng" ,email:"sp5@makingautoeasy.com"},
            6:{name:"sp6 Dev Bhardwaj" ,email:"sp6@makingautoeasy.com"},
            7:{name:"sp7 Nicole Bullock" ,email:"sp7@makingautoeasy.com"},
            8:{name:"sp8 Emre Celik" ,email:"sp8@makingautoeasy.com"}
        }
    },
    "trial":{
        name: "Your Dealership Name",
        imgUrl: "https://mae-trim-images.s3.us-east-2.amazonaws.com/Black+and+White+Illustrative+Auto+Dealership+Logo+Design+(2)+(1).png",
        linkMatchesToVDP: false,
        defaultInventoryImage: "https://mae-trim-images.s3.us-east-2.amazonaws.com/Black+and+White+Illustrative+Auto+Dealership+Logo+Design+(3).png"
    },
    [ANR_ID]:{
        name: "Applewood Nissan Richmond",
        imgUrl: "https://mae-trim-images.s3.us-east-2.amazonaws.com/Black+and+White+Illustrative+Auto+Dealership+Logo+Design+(5)+(1).png",
        linkMatchesToVDP: true,
        defaultInventoryImage: false,
        salesPeople: {
            0:{name:"",email:""},
            1:{name:"sp1 Applewood", email:"sp1@makingautoeasy.com"},
            2:{name:"sp2 Applewood",email:"sp2@makingautoeasy.com"},
            3:{name:"sp3 Applewood" ,email:"sp3@makingautoeasy.com"},
            4:{name:"sp4 Applewood" ,email:"sp4@makingautoeasy.com"},
            5:{name:"sp5 Applewood" ,email:"sp5@makingautoeasy.com"},
            6:{name:"sp6 Applewood" ,email:"sp6@makingautoeasy.com"},
            7:{name:"sp7 Applewood" ,email:"sp7@makingautoeasy.com"},
            8:{name:"sp8 Applewood" ,email:"sp8@makingautoeasy.com"}
        }
    },
}