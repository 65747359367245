export const CCFQuizIndexMappingV0 = {
  IKIDK_INDEX: 0,
  IK_VEHICLE_INPUT_INDEX: 1,
  REASON_NEW_VEHICLE_INDEX: 2,
  VEHICLE_TYPE_INDEX: 3,
  VEHICLE_ENGINE_INDEX: 4,
  EV_SPECIFIC_Q1_INDEX: 5,
  EV_SPECIFIC_Q2_INDEX: 6,
  LIFESTYLE_INDEX: 7,
  FEATURE_SELECT_INDEX: 8,
  VALUE_RANKING_INDEX: 9,
  BUDGET_INDEX: 10,
  NEW_USED_INDEX: 11,
  TRADE_IN_INDEX: 12,
  TRADE_IN_INFO_INDEX: 13,
  PAYMENT_MODE_INDEX: 14,
  PICK_NEW_CARS_INDEX: 15,
  PICK_CARS_INDEX: 16,
  USER_DETAILS_INDEX: 17,
};

export const CCFQuizIndexMappingV1 = {
  IKIDK_INDEX: 0,
  REASON_NEW_VEHICLE_INDEX: 1,
  VEHICLE_TYPE_INDEX: 2,
  VEHICLE_ENGINE_INDEX: 3,
  EV_SPECIFIC_Q1_INDEX: 4,
  EV_SPECIFIC_Q2_INDEX: 5,
  LIFESTYLE_INDEX: 6,
  FEATURE_SELECT_INDEX: 7,
  BUDGET_INDEX: 8,
  NEW_USED_INDEX: 9,
  TRADE_IN_INDEX: 10,
  PAYMENT_MODE_INDEX: 11,
  PICK_CARS_INDEX: 12,
  USER_DETAILS_INDEX: 13,
};

export const ANRQuizIndexMapping = {
  IKIDK_INDEX: 0,
  REASON_NEW_VEHICLE_INDEX: 1,
  VEHICLE_TYPE_INDEX: 2,
  VEHICLE_ENGINE_INDEX: 3,
  LIFESTYLE_INDEX: 4,
  FEATURE_SELECT_INDEX: 5,
  BUDGET_INDEX: 6,
  NEW_USED_INDEX: 7,
  TRADE_IN_INDEX: 8,
  PAYMENT_MODE_INDEX: 9,
  PICK_CARS_INDEX: 10,
  USER_DETAILS_INDEX: 11,
};