import { createContext, useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { DEALERSHIP_CONFIG } from "../config/dealershipConfig";

const DealershipContext = createContext("dealershipName");

export const DealershipProvider = ({ children }) => {

  const location = useLocation();
  const navigate = useNavigate();
  const getUrlDealershipId = () => location.pathname.split('/')[1] || "trial";
  const [dealershipId, setDealershipId] = useState(getUrlDealershipId());
  const [salesPersonInfo, setSalesPersonInfo] = useState(["",""])
  const [dealershipName, setDealershipName] = useState(DEALERSHIP_CONFIG[getUrlDealershipId()]?.name);
  const [dealershipImgUrl, setDealershipImgUrl] = useState(DEALERSHIP_CONFIG[getUrlDealershipId()]?.imgUrl);
  const [linkMatchesToVDP, setLinkMatchesToVdp] = useState(DEALERSHIP_CONFIG[getUrlDealershipId()]?.linkMatchesToVDP);
  const [defaultInventoryImage, setDefaultInventoryImage] = useState(DEALERSHIP_CONFIG[getUrlDealershipId()]?.defaultInventoryImage);
  const [userSource, setUserSource] = useState("Walk-In");
  const [initContextFlag, setInitContextFlag] = useState(false)
  
  useEffect(() => {
    const urlDealershipId = getUrlDealershipId();
    console.log(urlDealershipId,"userDealershipId");
    if (urlDealershipId in DEALERSHIP_CONFIG) {
      setDealershipId(urlDealershipId);
      setDealershipName(DEALERSHIP_CONFIG[urlDealershipId].name)
      setDealershipImgUrl(DEALERSHIP_CONFIG[urlDealershipId].imgUrl)
      setLinkMatchesToVdp(DEALERSHIP_CONFIG[urlDealershipId].linkMatchesToVDP)
      setDefaultInventoryImage(DEALERSHIP_CONFIG[urlDealershipId].defaultInventoryImage)
    }
    // } else {
    //   navigate('/')
    // }

    console.log(DEALERSHIP_CONFIG[urlDealershipId])

    const localStorageUserSource = localStorage.getItem("dealership-user-source");

    if (localStorageUserSource === null) {
      const params = new URLSearchParams(location.search);
      const sourceParam = params.get("source");

      if (sourceParam) {
        setUserSource(sourceParam);
        localStorage.setItem("dealership-user-source", sourceParam);
      }
    } else {
      setUserSource(localStorageUserSource);
    }

    const params = new URLSearchParams(location.search);
    const salespersonParam = params.get("sp");

    const localStorageSalesPersonInfo = localStorage.getItem("dealership-salesperson-id")

    if (salespersonParam) {
      const salesPersonInfoTemp = DEALERSHIP_CONFIG[urlDealershipId].salesPeople[salespersonParam]
      if (salesPersonInfoTemp){
        setSalesPersonInfo(salesPersonInfoTemp);
        localStorage.setItem("dealership-salesperson-id", [salesPersonInfoTemp.name, salesPersonInfoTemp.email]);
      }
    } else if (localStorageSalesPersonInfo !== null) {
      //check if the local storage sales person is in the dealerships
      setSalesPersonInfo({name:localStorageSalesPersonInfo.split(",")[0],email:localStorageSalesPersonInfo.split(",")[0]});
    } 

    setInitContextFlag(true)
  }, [location, location.pathname, location.search]);

  const clearDealershipUserSource = () => {
    localStorage.removeItem("dealership-user-source")
  }

  const clearSalesPersonInfo = () => {
    localStorage.removeItem("dealership-salesperson-id")
  }

  const changeSalesPersonInfo = (newValue) => {
    setSalesPersonInfo(newValue)
    localStorage.setItem("dealership-salesperson-id", [newValue.name,newValue.email]);
    console.log(salesPersonInfo)
  }

  return (
    <DealershipContext.Provider
      value={{
        dealershipName,
        setDealershipName,
        dealershipImgUrl,
        dealershipId,
        linkMatchesToVDP,
        defaultInventoryImage,
        clearDealershipUserSource,
        clearSalesPersonInfo,
        userSource,
        salesPersonInfo,
        changeSalesPersonInfo,
        initContextFlag
      }}
    >
      {children}
    </DealershipContext.Provider>
  );
};

export const useDealershipContext = () => {
  return useContext(DealershipContext);
};
