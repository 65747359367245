export const ELECTRIC_INCENTIVE_QUIZ_FACT = {
    headText : "Did You Know...",
    infoText : "BC Government incentives is currently offering up to $9,000 on eligible new Plug-In Hybrid or Full Electric vehicle!",
    img : "electric-fact.svg"
  }
  
  export const FEATURE_QUIZ_FACT = {
    headText : "Did You Know?",
    infoText : "As of 2018, all New cars sold  in Canada must have a Back-up Camera installed. Same thing for Automatic Headlights & Taillights in 2021.",
    img : "teacher-explaining.svg"
  }
  
  export const VALUE_QUIZ_FACT = {
    headText : "Reminder to Think Ahead",
    infoText : "We always recommend thinking ahead about your lifestyle in the coming years. And a reminder to be thoughtful about upgrades as resale value is another important consideration for you at this time!",
    img : "woman-driving.svg"
  }