import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";

function Home() {
    
  return (
    <LoadingScreen />
  );
}

export default Home;
