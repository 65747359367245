import cloneDeep from 'lodash.clonedeep';
import { CCFQuizQuestions } from './CCFQuizQuestions';
import { ANRQuizQuestions } from './ANRQuizQuestions';
import { ANR_ID, CCF_ID } from '../../constants/dealershipIds';

export const quizQuestionsFactory = (dealershipId) => {
    switch(dealershipId) {
        case CCF_ID : return cloneDeep(CCFQuizQuestions)
        case ANR_ID : return cloneDeep(ANRQuizQuestions)
        default : return {}
    }
}