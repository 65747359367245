import './QICustomerDetailsForm.scss';
import { useState, useEffect } from "react";
import FormFieldWithLabel from "../FormFieldWithLabel/FormFieldWithLabel.jsx";
import { produce } from "immer";
import { DEALERSHIP_CONFIG } from '../../config/dealershipConfig.js';
import { useDealershipContext } from '../../contexts/dealershipContext.jsx';

export default function QICustomerDetailsForm({ dealershipId, currentQuestionViewIndex, quizQuestions ,setQuizQuestions,childIndex }) {
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNoError, setPhoneNoError] = useState("");
  const {salesPersonInfo, changeSalesPersonInfo} = useDealershipContext();
  const [salesPerson, setSalesPerson] = useState(salesPersonInfo.name);
  const salesDropdownInfo = DEALERSHIP_CONFIG[dealershipId].salesPeople;

  const getSalesPersonByName = (targetName) => {
    for (const key in salesDropdownInfo) {
      if (salesDropdownInfo[key].name === targetName) {
        return salesDropdownInfo[key]; 
      }
    }
    return null; 
  }


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailRegex.test(email)) {
      setEmailError("Invalid Email");
      return false;
    }
    setEmailError("");
    return true;
  };

  const validatePhoneNo = (phoneNo) => {
    const phoneNoRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    if (phoneNo && !phoneNoRegex.test(phoneNo)) {
      setPhoneNoError("Invalid phone number");
      return false;
    }
    setPhoneNoError("");
    return true;
  };

  return (
    <>
      <div className="combine-fields">
        <FormFieldWithLabel
          label="First Name"
          type="text"
          id="first-name"
          placeholder="John"
          value={quizQuestions[currentQuestionViewIndex].children[childIndex].firstName}
          onChange={(e) => {
            setQuizQuestions(
              produce((quizQuestionsDraft) => {
                quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].firstName =
                  e.target.value;
              })
            );
          }}
          errorMessage={""}
        />
        <FormFieldWithLabel
          label="Last Name"
          type="text"
          id="last-name"
          placeholder="Terry"
          value={quizQuestions[currentQuestionViewIndex].children[childIndex].lastName}
          onChange={(e) => {
            setQuizQuestions(
              produce((quizQuestionsDraft) => {
                quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].lastName =
                  e.target.value;
              })
            );
          }}
          errorMessage={""}
        />
      </div>
        <FormFieldWithLabel
          label="Email (Optional)"
          type="text"
          id="email"
          placeholder="johnterry@hotmail.com"
          value={email}
          onChange={(e) => {
            if (validateEmail(e.target.value)) {
              setQuizQuestions(
                produce((quizQuestionsDraft) => {
                  quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].email = e.target.value;
                })
              );
            }
            setEmail(e.target.value);
          }}
          errorMessage={emailError}
        />
        <FormFieldWithLabel
          label="Phone No."
          type="text"
          id="phone-no"
          placeholder="604 888 8888"
          value={phoneNo}
          onChange={(e) => {
            if (validatePhoneNo(e.target.value)) {
              setQuizQuestions(
                produce((quizQuestionsDraft) => {
                  quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].phoneNo =
                    e.target.value;
                })
              );
            }
            setPhoneNo(e.target.value);
          }}
          errorMessage={phoneNoError}
        />
      <div className="dropdown-group">
        <label htmlFor="contact-type">Preferred Method of Contact</label>
        <select
          value={quizQuestions[currentQuestionViewIndex].children[childIndex].preferredMethodOfContact}
          onChange={(e) => {
            setQuizQuestions(
              produce((quizQuestionsDraft) => {
                quizQuestionsDraft[
                  currentQuestionViewIndex
                ].preferredMethodOfContact = e.target.value;
              })
            );
          }}
          className="dropdown"
        >
          <option value="email">Email</option>
          <option value="phone">Phone</option>
        </select>
      </div>

      <div className="dropdown-group">
        <label htmlFor="contact-type">Sales Person (Optional)</label>
        <select
          value={salesPerson}        
          onChange={(e) => {
            const salesPersonDetails = getSalesPersonByName(e.target.value)
            console.log(salesPersonDetails)
            changeSalesPersonInfo(salesPersonDetails)
            setSalesPerson(salesPersonDetails.name)
            setQuizQuestions(
              produce((quizQuestionsDraft) => {
                quizQuestionsDraft[
                  currentQuestionViewIndex
                ].children[childIndex].salesPersonInfo = e.target.value;
               
              })
            );
          }}
          className="dropdown"
        >
          {
            Object.keys(salesDropdownInfo).map((salesPersonNumber,index) => {
              return (
                <option key={index} value={salesDropdownInfo[salesPersonNumber].name}>{salesDropdownInfo[salesPersonNumber].name}</option>
              )
            })
          }
        </select>
      </div>
  </>
  );
}
