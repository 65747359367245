import "./QuizProgressBar.scss";
import { useState } from "react";

// function QuizProgressBar({ count, active }) {
//   return (
//     <div className="progress-wrapper">
//       {Array.from(
//         {
//           length: count,
//         },
//         (_, index) => (
//           <div className={`progress-wrapper__bar ${
//               index <= active ? "progress-wrapper__bar--selected" : ""
//             }`}
//           key={index} />
//         )
//       )}
//     </div>
//   );
// }

function QuizProgressBar({ count, active }) {
  const progress_percent = (++active/count)*100
  // alert(progress_percent);
  return (
    <div className="progress-wrapper">
       <div style={{width : `${progress_percent}%`}} className={`progress-wrapper__bar`} />
    </div>
  );
}
export default QuizProgressBar;
