import './QIRanking.scss';
import { produce } from 'immer';
import {ReactComponent as ChevronUp} from '../../assets/icons/chevron-up.svg';
import {ReactComponent as ChevronDown} from '../../assets/icons/chevron-down.svg';

export default function QIRanking ({ quizQuestions, setQuizQuestions, currentQuestionViewIndex, childIndex }) {
  function increaseRanking(valueOption) {
    setQuizQuestions(produce((quizQuestionsDraft) => {
      const rankingOptionsArr = quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].rankingOptions;
      const rankingArr = quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].ranking;
      if(rankingArr[valueOption] < 5) {
        const prevRank = rankingArr[valueOption]; 
        rankingArr[valueOption]++;
        Object.keys(rankingArr).forEach((value, _) => {
          if(value !== valueOption && rankingArr[value] === rankingArr[valueOption]) {
            rankingArr[value] = prevRank;
          }
          rankingOptionsArr[rankingArr[value]-1] = value;
        })
      }
    }))
}

  function decreaseRanking(valueOption,_) {
    setQuizQuestions(produce((quizQuestionsDraft) => {
      const rankingOptionsArr = quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].rankingOptions;
      const rankingArr = quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].ranking;
      if(rankingArr[valueOption] > 1) {
        const prevRank = rankingArr[valueOption]; 
        rankingArr[valueOption]--;
        Object.keys(rankingArr).forEach((value, _) => {
          if(value !== valueOption && rankingArr[value] === rankingArr[valueOption]) {
            rankingArr[value] = prevRank;
          } 
          rankingOptionsArr[rankingArr[value]-1] = value;
        })
      }
    }))
  }

  return (
    <main className="value_ranking_main">
      {quizQuestions[currentQuestionViewIndex].children[childIndex].rankingOptions.map((valueOption, index) => (
        <div className='value_ranking_main__options' key={index}>
          <div className='value_ranking_main__options--rank value_ranking_component_text'>
            <div className='numbering'>{quizQuestions[currentQuestionViewIndex].children[childIndex].ranking[valueOption]}</div>
            <div className='arrow-box'><ChevronUp onClick={() => decreaseRanking(valueOption)}/>
              <ChevronDown onClick={() => increaseRanking(valueOption)}/></div>
          </div>
          <div
            className='value_ranking_main__options--drag_div value_ranking_component_text'
          >
            <p className='option_text'>{valueOption}</p>
          </div>
        </div>
      ))}
    </main>
  );
}
