import "./QIBudgetSelector.scss";
import { useState } from "react";
import {
  MIN_BUDGET_VALUES,
  MAX_BUDGET_VALUES,
  MONTHLY_BUDGET_VALUES,
} from "../../data/budgetData";
import { produce } from "immer";

export default function QIBudgetSelector({  currentQuestionViewIndex, quizQuestions ,setQuizQuestions,childIndex }) {
  const questionObj = quizQuestions[currentQuestionViewIndex].children[childIndex];
  const [minBudget, setMinBudget] = useState(questionObj.minBudget);
  const [maxBudget, setMaxBudget] = useState(questionObj.maxBudget);
  const [monthlyBudget, setMonthlyBudget] = useState(questionObj.monthlyBudget);

  function addMinBudget(minBudgetValue) {
    setMinBudget(minBudgetValue);
    setMonthlyBudget("");
    setQuizQuestions(
      produce((quizQuestionsDraft) => {
        const budgetQuizObj = quizQuestionsDraft[currentQuestionViewIndex].children[childIndex];
        budgetQuizObj.monthlyBudget = "";
        budgetQuizObj.minBudget = minBudgetValue;
        budgetQuizObj.currSelected =
          !minBudgetValue || !maxBudget
            ? []
            : [`${minBudgetValue} - ${maxBudget}`];
      })
    );
  }

  function addMaxBudget(maxBudgetValue) {
    setMaxBudget(maxBudgetValue);
    setMonthlyBudget("");
    setQuizQuestions(
      produce((quizQuestionsDraft) => {
        const budgetQuizObj = quizQuestionsDraft[currentQuestionViewIndex].children[childIndex];
        budgetQuizObj.monthlyBudget = "";
        budgetQuizObj.maxBudget = maxBudgetValue;
        budgetQuizObj.currSelected =
          !maxBudgetValue || !minBudget
            ? []
            : [`${minBudget} - ${maxBudgetValue}`];
      })
    );
  }

  function addMonthlyBudget(monthlyBudgetValue) {
    setMaxBudget("");
    setMinBudget("");
    setMonthlyBudget(monthlyBudgetValue);
    setQuizQuestions(
      produce((quizQuestionsDraft) => {
        const budgetQuizObj = quizQuestionsDraft[currentQuestionViewIndex].children[childIndex];
        budgetQuizObj.minBudget = "";
        budgetQuizObj.maxBudget = "";
        budgetQuizObj.monthlyBudget = monthlyBudgetValue;
        budgetQuizObj.currSelected = [monthlyBudgetValue];
      })
    );
  }

  return (
    <div className="container">
      <div className="dropdown-container">
        <div className="dropdown-group">
          <label htmlFor="min_budget">Minimum Budget (Total Cost)</label>
          <select
            value={minBudget}
            onChange={(e) => addMinBudget(e.target.value)}
            className="dropdown"
          >
            <option value="" disabled hidden>
              Select Minimum Budget
            </option>
            {MIN_BUDGET_VALUES.map((item, index) => {
              return (
                (item < maxBudget || !maxBudget) && (
                  <option key={index} value={item}>
                    {item}
                  </option>
                )
              );
            })}
          </select>
        </div>
        <div className="dropdown-group">
          <label htmlFor="minBudget">Maximum Budget (Total Cost)</label>
          <select
            value={maxBudget}
            onChange={(e) => addMaxBudget(e.target.value)}
            className="dropdown"
          >
            <option value="">Select Maximum Budget</option>
            {MAX_BUDGET_VALUES?.map((item, index) => {
              return (
                (item > minBudget || !minBudget) && (
                  <option key={index} value={item}>
                    {" "}
                    {item}{" "}
                  </option>
                )
              );
            })}
          </select>
        </div>
        <div className="budget-divider">OR</div>
        <div className="dropdown-group">
          <label htmlFor="monthly_budget">Monthly Budget</label>
          <select
            value={monthlyBudget}
            onChange={(e) => addMonthlyBudget(e.target.value)}
            className="dropdown"
          >
            <option value="" disabled hidden>
              Select Monthly Budget
            </option>
            {MONTHLY_BUDGET_VALUES.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.text}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
}
