import "./LikedList.scss";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import EllipsePercentMatch from "../EllipsePercentMatch/EllipsePercentMatch";
import { formatPrice } from "../../utils/formatUtils";
import {
  getDealershipUserInfo,
  updateComparisonChartStates,
} from "../../services/dealership-user";
import RestartButtonContainer from "../RestartButton/RestartButton";
import discountedGreenIcon from "../../assets/icons/discount-green.png";
import discountedRedIcon from "../../assets/icons/discount-red.png";
import { useDealershipContext } from "../../contexts/dealershipContext";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import ButtonGradient from "../ButtonGradient/ButtonGradient";
import { ReactComponent as MaeCoreLogoYellow } from "../../assets/logos/Mae-Minimized-Logo-Yellow.svg";
import NoMatches from "../NoMatches/NoMatches";
import ComparisonChartStatic from "../ComparisonChartStatic/ComparisonChartStatic";
import { ReactComponent as Star } from "../../assets/icons/star.svg";
import { AGED_INVENTORY } from "../../data/agedInventory";
import UserProfile from "../UserProfile/UserProfile";
import { ReactComponent as CompareSelected } from "../../assets/icons/compare-selected.svg";
import { ReactComponent as ChevronUp } from "../../assets/icons/chevron-up.svg";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import { CCF_ID } from "../../constants/dealershipIds";

export function MatchSection({ vehicle }) {
  return (
    <div className="liked-card-match">
      {" "}
      <div className="liked-card-match__circle">
        <div className="liked-card-match-coloured-circle">
          <EllipsePercentMatch percentage={vehicle.compatibilityPercentage} />
        </div>
        <div className="liked-card-match-stamp">
          <p className="liked-card-match-stamp__number">
            {vehicle.compatibilityPercentage}
            <span className="liked-card-match-stamp__percent">%</span>
          </p>
          <p className="liked-card-match-stamp__text">match</p>
        </div>
      </div>
      <div className="match__text">
        <div className="match__text--row">
          <p className="text-bold">8/8</p>
          <p className="text-normal">requirements</p>
        </div>
        <div className="match__text--row">
          <p className="text-bold">
            {" "}
            {vehicle.matchedFeatures.length}/{vehicle.totalFeatures.length}
          </p>
          <p className="text-normal">features</p>
        </div>
      </div>
    </div>
  );
}

function LikedList() {
  const navigate = useNavigate();
  const {
    dealershipName,
    dealershipId,
    linkMatchesToVDP,
    dealershipImgUrl,
    defaultInventoryImage,
    initContextFlag
  } = useDealershipContext();
  const [carMatches, setCarMatches] = useState([]);
  const [maeCarMatches, setMaeCarMatches] = useState([]);
  const [carsToCompareList, setCarsToCompareList] = useState([]);
  //const [finalCarCompareList,setFinalCarCompareList] = useState([]);
  const [zeroMatchesCase, setZeroMatchesCase] = useState(false);
  const [user, setUser] = useState({});
  const targetDivRef = useRef(null);
  const cardContainerRef = useRef(null);
  const [showAllCars, setShowAllCars] = useState(false);
  const [numCarsToShow, setNumCarsToShow] = useState(4);
  const [userID, setUserID] = useState(null);

  const [openedThroughEmail, setOpenedThroughEmail] = useState(null);

  useEffect(() => {
    if (showAllCars) {
      setNumCarsToShow(carMatches.length);
    } else if (window.innerWidth < 600) {
      setNumCarsToShow(1);
    } else if (window.innerWidth < 850) {
      setNumCarsToShow(2);
    } else if (window.innerWidth < 1100) {
      setNumCarsToShow(3);
    } else if (window.innerWidth < 1250) {
      setNumCarsToShow(4);
    } else {
      setNumCarsToShow(5);
    }
  }, [showAllCars, window.innerWidth]);

  const lockScroll = () => {
    const scrollPosition = window.scrollY;
    window.scrollTo({ top: scrollPosition });
  };

  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    setOpenedThroughEmail(params.get("email"));
    setUserID(params.get("id"));
  }, []);

  useEffect(() => {
    const fetchUserInfo = async (id, email) => {
      const res = await getDealershipUserInfo(
        id,
        email,
        dealershipId
      );
      setUser(res.user);
      console.log(res, "res");

      if (res) {
        if (res.matches === 0) {
          console.log("setting 0 match case true");
          setZeroMatchesCase(true);
        } else {
          const inventoryMatchesWithTags = addmaeInsights(res.inventoryMatches , res.user);
          setCarMatches(inventoryMatchesWithTags);
          if (res.user.comparisonChartStates.at(-1)) {
            const currComparisonChartStates =
              res.user.comparisonChartStates.at(-1);
            const currCarsToCompareList = inventoryMatchesWithTags
              .map((cars, itr) => {
                return currComparisonChartStates.includes(cars.maeId)
                  ? itr
                  : null;
              })
              .filter((item) => item !== null);
            setCarsToCompareList(currCarsToCompareList);
          } else {
            const currComparisonChartStates = inventoryMatchesWithTags
              .slice(0, 3)
              .map((cars, _) => {
                return cars.maeId;
              });
            await updateComparisonChartStates(id, currComparisonChartStates);
            const maxThreeSelect = [];
            for (let i = 0; i < inventoryMatchesWithTags.length; i++) {
              if (i > 2) {
                break;
              }
              maxThreeSelect.push(i);
            }
            setCarsToCompareList(maxThreeSelect);
          }
          setMaeCarMatches(res.maeTrimsMatches);
        }
      } else {
        navigate("/404");
      }
    };

    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    console.log(params, "params", params.get("id"), "id");
    if (carMatches.length <= 0 && initContextFlag) {
      fetchUserInfo(params.get("id"), params.get("email"));
    }
  }, [initContextFlag]);

  const addmaeInsights = (inventoryMatches, user) => {

    let sortedCarsByPrice = [...inventoryMatches];

    sortedCarsByPrice = sortedCarsByPrice.sort(
      (a, b) => a.baseMSRP - b.baseMSRP
    );

    console.log(sortedCarsByPrice)

    const lowestAWDCarId = sortedCarsByPrice.find(
      (car) => ["All Wheel Drive", "4x4","All-wheel drive","Four-wheel drive"].includes(car.driveTrain)
    )?.id;
    // const lowestFWDCarId = sortedCarsByPrice.find(car => car.driveTrain == "Front Wheel Drive")?.id;
    const lowestUsedAWDCarId = sortedCarsByPrice.find(
      (car) =>
        ["All Wheel Drive", "4x4","All-wheel drive","Four-wheel drive"].includes(car.driveTrain) &&
        car.newOrUsed == "Used"
    )?.id;
    const lowestNewAWDCarId = sortedCarsByPrice.find(
      (car) =>
        ["All Wheel Drive", "4x4","All-wheel drive","Four-wheel drive"].includes(car.driveTrain) &&
        car.newOrUsed == "New"
    )?.id;
    // const lowestUsedFWDCarId = sortedCarsByPrice.find(car => car.driveTrain == "Front Wheel Drive" && car.newOrUsed == "Used")?.id;
    // const lowestNewFWDCarId = sortedCarsByPrice.find(car => car.driveTrain == "Front Wheel Drive" && car.newOrUsed == "New")?.id;
    const lowestHybridCarId = sortedCarsByPrice.find(
      (car) => car.engine == "Hybrid"
    )?.id;
    const lowestUsedHybridCarId = sortedCarsByPrice.find(
      (car) => car.engine == "Hybrid" && car.newOrUsed == "Used"
    )?.id;
    const lowestNewHybridCarId = sortedCarsByPrice.find(
      (car) => car.engine == "Hybrid" && car.newOrUsed == "New"
    )?.id;
    const lowestPHEVCarId = sortedCarsByPrice.find(
      (car) => car.engine == "PHEV"
    )?.id;
    const lowestUsedPHEVCarId = sortedCarsByPrice.find(
      (car) => car.engine == "PHEV" && car.newOrUsed == "Used"
    )?.id;
    const lowestNewPHEVCarId = sortedCarsByPrice.find(
      (car) => car.engine == "PHEV" && car.newOrUsed == "New"
    )?.id;
    const lowestElectricCarId = sortedCarsByPrice.find(
      (car) => car.engine == "Electric"
    )?.id;
    const lowestUsedElectricCarId = sortedCarsByPrice.find(
      (car) => car.engine == "Electric" && car.newOrUsed == "Used"
    )?.id;
    const lowestNewElectricCarId = sortedCarsByPrice.find(
      (car) => car.engine == "Electric" && car.newOrUsed == "New"
    )?.id;
    const lowestLargeSUVCarId = sortedCarsByPrice.find(
      (car) => car.maeSegment == "Medium/Large SUV"
    )?.id;
    const lowestSmallSUVCarId = sortedCarsByPrice.find(
      (car) => car.maeSegment == "Small/Medium SUV"
    )?.id;

    inventoryMatches.forEach((car) => {
      car.maeInsights = [];

      car.driveTrainShort = car.driveTrain;
      if (car.driveTrain === "All Wheel Drive" || car.driveTrain === "All-wheel drive") {
        car.driveTrainShort = "AWD";
      }
      if (car.driveTrain === "Rear Wheel Drive" || car.driveTrain === "Rear-wheel drive") {
        car.driveTrainShort = "RWD";
      }
      if (car.driveTrain === "Front Wheel Drive" || car.driveTrain === "Front-wheel drive") {
        car.driveTrainShort = "FWD";
      }
      if (car.driveTrain === "Four-wheel drive") {
        car.driveTrainShort = "4x4";
      } 

      if (AGED_INVENTORY[dealershipId]["list"].includes(car[AGED_INVENTORY[dealershipId].mappingKey])) {
        car.maeInsights.push("Manager's Special");
        car.agedInventory = true;
      }

      if (lowestUsedAWDCarId && lowestNewAWDCarId) {
        if (car.id === lowestUsedAWDCarId) {
          car.maeInsights.push(`Lowest-priced Used ${car.driveTrainShort}`);
        }
        if (car.id === lowestNewAWDCarId) {
          car.maeInsights.push(`Lowest-priced New ${car.driveTrainShort}`);
        }
      } else {
        if (car.id === lowestAWDCarId) {
          car.maeInsights.push(`Lowest-priced ${car.driveTrainShort}`);
        }
      }

      // if (lowestUsedFWDCarId && lowestNewFWDCarId) {
      //   if (car.id === lowestUsedFWDCarId) {
      //     car.maeInsights.push("Lowest$ Used FWD";
      //   }
      //   if (car.id === lowestNewFWDCarId) {
      //     car.maeInsights.push("Lowest$ New FWD";
      //   }
      // } else {
      //   if (car.id === lowestFWDCarId) {
      //     car.maeInsights.push("Lowest$ FWD";
      //   }
      // }

      if (lowestUsedElectricCarId && lowestNewElectricCarId) {
        if (car.id === lowestUsedElectricCarId) {
          car.maeInsights.push("Lowest-priced Used Electric");
        }
        if (car.id === lowestNewElectricCarId) {
          car.maeInsights.push("Lowest-priced New Electric");
        }
      } else {
        if (car.id === lowestElectricCarId) {
          car.maeInsights.push("Lowest-priced Electric");
        }
      }

      if (lowestUsedHybridCarId && lowestNewHybridCarId) {
        if (car.id === lowestUsedHybridCarId) {
          car.maeInsights.push("Lowest-priced Used Hybrid");
        }
        if (car.id === lowestNewHybridCarId) {
          car.maeInsights.push("Lowest-priced New Hybrid");
        }
      } else {
        if (car.id === lowestHybridCarId) {
          car.maeInsights.push("Lowest-priced Hybrid");
        }
      }

      if (lowestUsedPHEVCarId && lowestNewPHEVCarId) {
        if (car.id === lowestUsedPHEVCarId) {
          car.maeInsights.push("Lowest-priced Used PHEV");
        }
        if (car.id === lowestNewPHEVCarId) {
          car.maeInsights.push("Lowest-priced New PHEV");
        }
      } else if (car.id === lowestPHEVCarId) {
        car.maeInsights.push("Lowest-priced PHEV");
      }

      if (lowestLargeSUVCarId && lowestSmallSUVCarId && car.id === lowestLargeSUVCarId) {
        car.maeInsights.push("Lowest-priced Large SUV");
      }
      
      if(user.selectedCarIds?.includes(car.maeId)) {
        car.maeInsights.push("Your Selected Car"); 
      }
    });

    return inventoryMatches;
  };

  if (carMatches.length === 0 && !zeroMatchesCase) {
    return <LoadingScreen />;
  }

  // if(zeroMatchesCase) {
  //   return <NoMatches/>
  // }

  const generateUserProfile = (event) => {
    event.preventDefault();
    const printContent = document.getElementById("print");
    const secondprintContent = document.getElementById("second-print");
    const noPrintHeader = document.getElementById("no-print-header");
    const noPrintHeaderTwo = document.getElementById("no-print-header-two");
    const noPrintList = document.getElementById("no-print-list");
    const noPrintListTwo = document.getElementById("no-print-list-two");
    const noPrintNoMatches = document.getElementById("no-print-nomatches");

    if (printContent) {
      printContent.style.display = "block";
    }

    if (secondprintContent) {
      secondprintContent.style.display = "none";
    }

    if (noPrintHeader) {
      noPrintHeader.style.display = "none";
    }

    if (noPrintHeaderTwo) {
      noPrintHeaderTwo.style.display = "none";
    }

    if (noPrintList) {
      noPrintList.style.display = "none";
    }

    if (noPrintListTwo) {
      noPrintListTwo.style.display = "none";
    }

    if (noPrintNoMatches) {
      noPrintNoMatches.style.display = "none";
    }
  };

  const generateChart = (event) => {
    event.preventDefault();
    const printContent = document.getElementById("print");
    const secondprintContent = document.getElementById("second-print");
    const noPrintHeader = document.getElementById("no-print-header");
    const noPrintHeaderTwo = document.getElementById("no-print-header-two");
    const noPrintList = document.getElementById("no-print-list");
    const noPrintListTwo = document.getElementById("no-print-list-two");
    const noPrintNoMatches = document.getElementById("no-print-nomatches");

    if (printContent) {
      printContent.style.display = "none";
    }

    if (secondprintContent) {
      secondprintContent.style.display = "block";
    }

    if (noPrintHeader) {
      noPrintHeader.style.display = "none";
    }

    if (noPrintHeaderTwo) {
      noPrintHeaderTwo.style.display = "none";
    }

    if (noPrintList) {
      noPrintList.style.display = "none";
    }

    if (noPrintListTwo) {
      noPrintListTwo.style.display = "none";
    }

    if (noPrintNoMatches) {
      noPrintNoMatches.style.display = "none";
    }
  };

  const backToMatches = (event) => {
    event.preventDefault();
    const printContent = document.getElementById("print");
    const secondprintContent = document.getElementById("second-print");
    const noPrintHeader = document.getElementById("no-print-header");
    const noPrintHeaderTwo = document.getElementById("no-print-header-two");
    const noPrintList = document.getElementById("no-print-list");
    const noPrintListTwo = document.getElementById("no-print-list-two");
    const noPrintNoMatches = document.getElementById("no-print-nomatches");

    if (printContent) {
      printContent.style.display = "none";
    }

    if (secondprintContent) {
      secondprintContent.style.display = "none";
    }

    if (noPrintHeader) {
      noPrintHeader.style.display = "block";
    }

    if (noPrintHeaderTwo) {
      noPrintHeaderTwo.style.display = "block";
    }

    if (noPrintList) {
      noPrintList.style.display = "block";
    }

    if (noPrintListTwo) {
      noPrintListTwo.style.display = "block";
    }

    if (noPrintNoMatches) {
      noPrintNoMatches.style.display = "block";
    }
  };

  const scrollToTargetDiv = () => {
    //setFinalCarCompareList(carsToCompareList);
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const expandFunctionality = () => {
    setShowAllCars(!showAllCars);
    if (showAllCars) {
      scrollToTargetDiv();
    }
    if (!showAllCars) {
      lockScroll();
    }
  };

  const selectCarForCompare = async (e, index) => {
    const currCarsToCompareList = [...carsToCompareList];
    const carIndexPos = currCarsToCompareList.indexOf(index);
    if (carIndexPos === -1 && carsToCompareList.length > 3) {
      return;
    }
    if (carIndexPos === -1 && carsToCompareList.length === 3) {
      currCarsToCompareList.splice(0, 1);
      currCarsToCompareList.push(index);
    } else if (carIndexPos === -1) {
      currCarsToCompareList.push(index);
    } else {
      currCarsToCompareList.splice(carIndexPos, 1);
    }
    const comparisonChartStates = carMatches
      .map((cars, itr) => {
        return currCarsToCompareList.includes(itr) ? cars.maeId : null;
      })
      .filter(Boolean);
    setCarsToCompareList(currCarsToCompareList);
    await updateComparisonChartStates(userID, comparisonChartStates);
    console.log(currCarsToCompareList, "currCars");
  };

  return (
    <div className="gallery" ref={targetDivRef}>
      <div className="gallery__hero" id="no-print-header">
        <div className="gallery__hero--main">
          <img
            className="product_info__dealership_logo"
            src={dealershipImgUrl}
            alt=""
          />
          <RestartButtonContainer dealershipId={dealershipId} />
        </div>
        {zeroMatchesCase && openedThroughEmail === "0" ? (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{ width: "15rem", marginTop: "1rem" }}>
              <ButtonGradient
                text="Print Profile"
                onClick={generateUserProfile}
              />
            </div>
          </div>
        ) : null}

        {zeroMatchesCase ? null : (
          <div className="matches_page_heading">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h1 className="matches_page_heading--text">
                {user.firstName}'s Matches
              </h1>
              <div
                className=".text"
                style={{
                  fontWeight: "400",
                  width: "auto",
                  marginLeft: "0.5rem",
                }}
              >
                Powered By
              </div>
              <div className=".logo" style={{ marginLeft: "0.5rem" }}>
                <MaeCoreLogoYellow />
              </div>
            </div>
            {openedThroughEmail === "1" ? null : (
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <ButtonGradient
                  text="Print Profile"
                  onClick={generateUserProfile}
                />
                <span style={{ width: "1rem" }} />
                <ButtonGradient text="Print Chart" onClick={generateChart} />
              </div>
            )}
          </div>
        )}

        {zeroMatchesCase ? null : (
          <p>
            Your profile has been saved! Our Concierge has personalized a list
            of matches in {dealershipName}’s inventory that best suits your
            profile - to save you time and hassle. Your Matches are now live and
            updated daily. Click the link in your inbox any time to come back
            and see updated and available matches.
          </p>
        )}

        {/* {zeroMatchesCase ? null :
          <div style={{width : "20%", marginInlineStart : "auto" , marginBlockStart : "1rem", display:"flex", justifyContent:"flex-end"}}> 
          <ButtonGradient text="Compare" onClick={scrollToTargetDiv}/> 
          </div> */}
      </div>

      {zeroMatchesCase ? (
        <div id="no-print-nomatches">
          {" "}
          <NoMatches />{" "}
        </div>
      ) : (
        <div
          ref={cardContainerRef}
          style={{ height: cardContainerRef, transition: "height 0.5s ease" }}
          className="liked-list"
          id="no-print-list"
        >
          <div className={`liked-list__wrapper`}>
            {carMatches.slice(0, numCarsToShow).map((vehicle, index) => {
              return (
                <div
                  key={index}
                  className={`car-card`}
                  onClick={(e) => selectCarForCompare(e, index)}
                >
                  <div className={`liked-card`}>
                    <div className="liked-card__thumbnail">
                      {
                        <div className="empty-selection">
                          {carsToCompareList.includes(index) ? (
                            <CompareSelected />
                          ) : null}
                        </div>
                      }
                      <img
                        className="liked-card__thumbnail--img"
                        src={
                          defaultInventoryImage
                            ? defaultInventoryImage
                            : vehicle.imageLink
                        }
                        alt={vehicle.make + vehicle.model}
                      />
                    </div>
                    <div className="liked-card__details">
                      <div style={{ display: "flex" }}>
                        <div
                          className={`liked-card__details--${vehicle.newOrUsed}`}
                        >
                          {`${vehicle.newOrUsed}`}
                        </div>
                        <div className={`liked-card__details--Other`}>
                          {`${vehicle.engine}`}
                        </div>
                        {vehicle.driveTrainShort && vehicle.driveTrainShort !== "MISSING_DRIVETRAIN" && (
                          <div className={`liked-card__details--Other`}>
                            {`${vehicle.driveTrainShort}`}
                          </div>
                        )}
                      </div>
                      {/* <div className={`liked-card__details--Other`}>
                    {`${vehicle.maeSegment}`}
                  </div> */}
                      <div className="liked-card__details--title">
                        {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                      </div>
                      <div
                        style={{ fontSize: "0.9rem" }}
                      >{`${vehicle.trim}`}</div>
                      <div className="liked-card__details--price">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          ${formatPrice(vehicle.baseMSRP)}
                          {vehicle.discountedFlag &&
                          !vehicle.agedInventory === true ? (
                            <img
                              className="liked-card__details--discount"
                              alt=""
                              src={discountedGreenIcon}
                            ></img>
                          ) : null}
                          {vehicle.agedInventory ? (
                            <img
                              className="liked-card__details--discount"
                              alt=""
                              src={discountedRedIcon}
                            />
                          ) : null}
                        </div>{" "}
                      </div>
                    </div>
                    {dealershipId === CCF_ID && <MatchSection vehicle={vehicle} /> }

                    <Link
                      className="liked-card__details--link"
                      to={linkMatchesToVDP ? vehicle.internalLink : null}
                      key={index}
                      target={linkMatchesToVDP ? "_blank" : "_self"}
                      onClick={(e) => {
                        if (!linkMatchesToVDP) {
                          e.preventDefault();
                        }
                      }}
                    >
                      Click to View Vehicle
                    </Link>

                    {vehicle.maeInsights.map((featureTag, index) => {
                      return featureTag === "Manager's Special" || 
                       featureTag === "Your Selected Car" ? (
                        <div
                          className="liked-card__featured-orange"
                          key={index}
                        >
                          <Star
                            style={{ width: "20px", marginRight: "0.2rem" }}
                          />{" "}
                          {featureTag}
                        </div>
                      ) : (
                        <div className="liked-card__featured" key={index}>
                          <Star
                            style={{ width: "20px", marginRight: "0.2rem" }}
                          />{" "}
                          {featureTag}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>

          {zeroMatchesCase && carMatches.length > numCarsToShow ? null : (
            <div className="button-container" onClick={expandFunctionality}>
              <div class="line"></div>
              <button class="view-button">
                {showAllCars ? "View less matches" : "View more matches"}
                {showAllCars ? <ChevronUp /> : <ChevronDown />}
              </button>
              <div class="line"></div>
            </div>
          )}
        </div>
      )}

      {maeCarMatches.length > 0 ? (
        <div className="gallery__hero" id="no-print-header-two">
          {zeroMatchesCase ? null : (
            <div className="matches_page_heading">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h1 className="matches_page_heading--text">
                  Ford Model Matches
                </h1>
              </div>
            </div>
          )}

          {zeroMatchesCase ? null : (
            <p> Matched to the recommended trim level</p>
          )}
        </div>
      ) : null}

      {maeCarMatches.length > 0 ? (
        <div className="liked-list" id="no-print-list-two">
          <div className={`liked-list__wrapper`}>
            {maeCarMatches.map((vehicle, index) => {
              return (
                <Link
                  to={linkMatchesToVDP ? vehicle.internalLink : null}
                  key={`mae-matches-${index}`}
                  target={linkMatchesToVDP ? "_blank" : "_self"}
                  onClick={(e) => {
                    if (!linkMatchesToVDP) {
                      e.preventDefault();
                    }
                  }}
                >
                  <div className={`liked-card`}>
                    <div className="liked-card__thumbnail">
                      <img
                        className="liked-card__thumbnail--img"
                        src={
                          defaultInventoryImage
                            ? defaultInventoryImage
                            : vehicle.imageLink
                        }
                        alt={vehicle.make + vehicle.model}
                      />
                    </div>
                    <div className="liked-card__details">
                      {/* <div className={`liked-card__details--${vehicle.newOrUsed}`}>
                {`${vehicle.newOrUsed}`}
              </div> */}
                      <div className="liked-card__details--title">
                        {`${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.trim}`}
                      </div>
                      <div className="liked-card__details--price">
                        {/* <div style={{display:"flex",alignItems:"center"}}>
                  ${formatPrice(vehicle.baseMSRP)}
                  {vehicle.discountedFlag?<img className="liked-card__details--discount" alt="" src={discountedIcon}></img>:null}
                </div>{" "} */}
                      </div>
                    </div>
                    <MatchSection vehicle={vehicle} />
                  </div>
                </Link>
              );
            })}
          </div>
          <ComparisonChartStatic
            targetDivRef={targetDivRef}
            carsToCompare={carMatches}
            name={user.firstName}
            quiz={user.profile.quiz}
            carsToCompareIndexes={carsToCompareList}
            matchesPage={true}
          />
        </div>
      ) : (
        <>
        {!zeroMatchesCase && <div className="liked-list" id="no-print-list-two">
          <ComparisonChartStatic
            targetDivRef={targetDivRef}
            carsToCompare={carMatches}
            name={user.firstName}
            quiz={user.profile.quiz}
            carsToCompareIndexes={carsToCompareList}
            matchesPage={true}
          />
        </div>}
        </>
      )}

      {/* Hidden Print-Only UserProfile component */}
      <div id="print">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "1rem",
          }}
        >
          <button
            style={{ background: "None", border: "None", fontSize: "1rem" }}
            onClick={backToMatches}
          >
            {" "}
            &#x25c0; Back
          </button>
          <button
            style={{ background: "None", border: "None", fontSize: "1rem" }}
            onClick={() => {
              window.print();
            }}
          >
            {" "}
            Print
          </button>
        </div>
        <UserProfile
          user={user}
          dealershipImgUrl={dealershipImgUrl}
          carMatches={carMatches}
          dealershipId={dealershipId}
        />
      </div>

      <div id="second-print">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "1rem",
          }}
        >
          <button
            style={{ background: "None", border: "None", fontSize: "1rem" }}
            onClick={backToMatches}
          >
            {" "}
            &#x25c0; Back
          </button>
          <button
            style={{ background: "None", border: "None", fontSize: "1rem" }}
            onClick={() => {
              window.print();
            }}
          >
            {" "}
            Print
          </button>
        </div>
        <ComparisonChartStatic
          carsToCompare={carMatches}
          name={user.firstName}
          quiz={user.profile.quiz}
          carsToCompareIndexes={carsToCompareList}
          matchesPage={false}
        />
      </div>
    </div>
  );
}

export default LikedList;
