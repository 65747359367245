export async function fetchDealershipInventoryList(dealershipId) {
    try {
        const response = await fetch(`/api/dealership/getinventorylist/${dealershipId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          }
        });
    
        if (response.ok) {
          console.log("Success:");
          return await response.json();
        }
        const errorData = await response.json();
        console.log("Failed:", errorData.error);
        return null;
      } catch (error) {
        console.log(error);
      }
}