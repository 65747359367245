import "./QISelectMakeModel.scss";
import { makesandmodels } from "../../data/makesandmodels";
import { useState, useEffect } from "react";
import { produce } from "immer";
import ButtonPlain from "../ButtonPlain/ButtonPlain";

export function SelectCarDropDown({
  dropdownIndex,
  currentQuestionViewIndex,
  setQuizQuestions,
  quizQuestions,
  childIndex,
  makesandmodelsObj,
  setMakesAndModelsObj,
}) {
  function getMakeValue() {
    // if (currentQuestionViewIndex === currentQuestionViewIndex) {
    //   return "Ford";
    // }
    if (quizQuestions[currentQuestionViewIndex].children[childIndex].currSelected[dropdownIndex]) {
      return quizQuestions[currentQuestionViewIndex].children[childIndex].currSelected[dropdownIndex]
        .make;
    }
    return "";
  }

  function getModelValue() {
    // if (
    //   currentQuestionViewIndex === currentQuestionViewIndex &&
    //   quizQuestions[currentQuestionViewIndex].children[childIndex].currSelected[dropdownIndex]
    // ) {
    //   return quizQuestions[currentQuestionViewIndex].children[childIndex].currSelected[dropdownIndex]
    //     .model;
    // }
    if (quizQuestions[currentQuestionViewIndex].children[childIndex].currSelected[dropdownIndex]) {
      return quizQuestions[currentQuestionViewIndex].children[childIndex].currSelected[dropdownIndex]
        .model;
    }
    return "";
  }

  const [carMake, setCarMake] = useState(getMakeValue());
  const [carModel, setCarModel] = useState(getModelValue());

  useEffect(() => {
    setMakesAndModelsObj(
      produce((makesandmodelsDraft) => {
        if (carMake && carModel) {
          makesandmodelsDraft[carMake][carModel].selected = true;
          makesandmodelsDraft[carMake][carModel].dropDownIndex = dropdownIndex;
        }
      })
    );
  });

  const handleMakeChange = (make) => {
    setCarMake(make);
    setCarModel("");
    setQuizQuestions(
      produce((quizQuestionsDraft) => {
        const carAdded =
          quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].currSelected[dropdownIndex];
        if (carAdded) {
          quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].currSelected[dropdownIndex] =
            { make: "", model: "" };
        }
        return quizQuestionsDraft;
      })
    );
  };

  const handleModelChange = (model) => {
    setCarModel(model);
    if (carMake && model) {
      setMakesAndModelsObj(
        produce((makesandmodelsDraft) => {
          makesandmodelsDraft[carMake][model].selected = true;
          makesandmodelsDraft[carMake][model].dropDownIndex = dropdownIndex;
          if (carModel) {
            makesandmodelsDraft[carMake][carModel].selected = false;
            makesandmodelsDraft[carMake][carModel].dropDownIndex = null;
          }
        })
      );
    }
    setQuizQuestions(
      produce((quizQuestionsDraft) => {
        const carAdded =
          quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].currSelected[dropdownIndex];
        const carAndMakeObj = { make: carMake, model };
        if (carAdded && carMake && model) {
          quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].currSelected[dropdownIndex] =
            carAndMakeObj;
        } else if (carAdded) {
          quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].currSelected[dropdownIndex] =
            { make: "", model: "" };
        } else {
          quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].currSelected.push(
            carAndMakeObj
          );
        }
        return quizQuestionsDraft;
      })
    );
  };

  return (
    <>
    <h4 className="vehicle_header">Vehicle {dropdownIndex + 1}</h4>
    <div className="dropdown-container">
      <div className="dropdown-group">
        <label htmlFor="carMake">Car Make</label>
        <select
          value={carMake}
          onChange={(e) => handleMakeChange(e.target.value)}
          className="dropdown"
          /*disabled={currentQuestionViewIndex === currentQuestionViewIndex}*/
        >
          <option value="" disabled hidden>
            Select Make
          </option>
          {Object.keys(makesandmodelsObj).map((item, index) => {
            return (
              <option key={index} value={item}>
                {item}
              </option>
            );
          })}
        </select>
      </div>
      <div className="dropdown-group">
        <label htmlFor="carMake">Car Model</label>
        <select
          value={carModel}
          onChange={(e) => handleModelChange(e.target.value)}
          className="dropdown"
        >
          <option value="">Select Model</option>
          {makesandmodelsObj[carMake] &&
            Object.keys(makesandmodelsObj[carMake]).map((item, index) => {
              return (
                (makesandmodelsObj[carMake][item].dropDownIndex ===
                  dropdownIndex ||
                  !makesandmodelsObj[carMake][item].selected) && (
                  <option key={index} value={item}>
                    {" "}
                    {item}{" "}
                  </option>
                )
              );
            })}
        </select>
      </div>
    </div>
    </>
  );
}

export default function QISelectMakeModel({currentQuestionViewIndex, quizQuestions ,setQuizQuestions,childIndex}) {

  const [noOfCarSelect, setNoOfCarSelect] = useState(quizQuestions[currentQuestionViewIndex].children[childIndex].currSelected.length);

  const [makesandmodelsObj, setMakesAndModelsObj] = useState(
    JSON.parse(JSON.stringify(makesandmodels))
  );

  useEffect (() => {
    const sortAlphabetically = (arr) => arr.sort((a, b) => a.localeCompare(b));

    let makesAndModelsTemp = JSON.parse(JSON.stringify(makesandmodels))

    const sortedKeys = Object.keys(makesAndModelsTemp).sort();

    const sortedMakesAndModels = {};
    sortedKeys.forEach((key) => {
      const models = Object.keys(makesAndModelsTemp[key]);
      const sortedModels = sortAlphabetically(models);

      sortedMakesAndModels[key] = {};
      sortedModels.forEach((model) => {
        sortedMakesAndModels[key][model] = makesAndModelsTemp[key][model];
      });
    });

    setMakesAndModelsObj(sortedMakesAndModels);
  },[])

  function handleAddMoreCarClick() {
    console.log(noOfCarSelect, "noOfCarSelect");
    setNoOfCarSelect(noOfCarSelect + 1);
  }

  return (
    <div className="container">
      {Array.from({ length: noOfCarSelect }).map((_, index) => {
        return (
          <SelectCarDropDown
            key={index}
            dropdownIndex={index}
            currentQuestionViewIndex={currentQuestionViewIndex}
            setQuizQuestions={setQuizQuestions}
            quizQuestions={quizQuestions}
            childIndex={childIndex}
            makesandmodelsObj={makesandmodelsObj}
            setMakesAndModelsObj={setMakesAndModelsObj}
          />
        );
      })}
      {noOfCarSelect < 5 && (
          <div className="add-more-car-btn-container">
            <ButtonPlain
              onClick={handleAddMoreCarClick}
              text="Add Another"
            />
          </div>
       )}
    </div>
  );
}
