/* eslint-disable react-hooks/exhaustive-deps */
import { produce } from "immer";
import FormFieldWithLabel from "../FormFieldWithLabel/FormFieldWithLabel";
import { useState, useEffect } from "react";
import { useDealershipContext } from "../../contexts/dealershipContext";
import './QIInventoryListInput.scss'
import { useFetchInventoryList } from "../../hooks/fetchInventoryList";



function QuizInputList( { filteredInventoryList, editAndFilterInputValue } ) {

  function handleMouseDownEv(input) {
    editAndFilterInputValue(input , true)
  }

  return (
    <div className="quiz-input-list-menu">
      {filteredInventoryList.map((item, index) => (
        <div key={index}>
          {item.trims && item.trims.length === 1 && 
          <div onMouseDown ={() => handleMouseDownEv(`${item.carName} ${item.trims[0]}`)} className="quiz-input-list-item">
            {item.carName}
            <span className="sub-item">
                  {item.trims[0]}
            </span>
          </div>}
          {item.trims && item.trims.length > 1 && <div onMouseDown ={() => handleMouseDownEv(`${item.carName}`)} className="quiz-input-list-item">{item.carName}</div>}
          {item.trims && item.trims.length > 1 && (
            <div className="quiz-input-list-sublist">
              {item.trims.map((trim, subIndex) => {
                return <div key={subIndex}>
                { trim ? <div onMouseDown ={() => handleMouseDownEv(`${item.carName} ${trim}`)} className="quiz-input-list-item sub-item">
                  {trim}
                </div> : null}
                </div>
               })}
            </div>
          )}
        </div> 
      ))}
    </div>
  )
}

export default function QIInventoryListInput({ currentQuestionViewIndex, childIndex, quizQuestions, setQuizQuestions }) {

  const { dealershipId } = useDealershipContext();
  const [ filteredInventoryList , setFilteredInventoryList ] = useState([]);
  const [inventoryListShow, setInventoryListShow] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { inventoryList, isRequestPending } = useFetchInventoryList(dealershipId, filteredInventoryList, setFilteredInventoryList);
  const [lastSearchText, setLastSearchText] = useState("");

  // useEffect(() => {
  //    if(filteredInventoryList?.length === 0) setFilteredInventoryList(inventoryList);
  // },[inventoryList])

  const openDropdown = () => {
    setInventoryListShow(true);
  };

  const closeDropdown = () => {
    setInventoryListShow(false);
  };



  useEffect(() => {
    if (inputValue) {
      const toFilterInventoryList = lastSearchText?.length < inputValue?.length && filteredInventoryList?.length ? filteredInventoryList : inventoryList;
      //console.log(toFilterInventoryList,"an inventory list",lastSearchText?.length,  inputValue?.length, inventoryList);
      const updatedFilteredInventoryList = toFilterInventoryList.map((group, _) => {
        const updatedTrims = group.trims.filter((trim) => 
           ( trim.toLowerCase().includes(inputValue.toLowerCase()) || 
             trim.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(inputValue.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()) )
            ||
            ( group.carName.toLowerCase().includes(inputValue.toLowerCase()) || 
            group.carName.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(inputValue.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()) )
        );
        return updatedTrims?.length > 0 ? {
          ...group,
          trims : updatedTrims
        } : null;
      }).filter(Boolean);
      setFilteredInventoryList(updatedFilteredInventoryList);
      return;
    }   
    setFilteredInventoryList(inventoryList);      
  },[inputValue, inventoryList])


  useEffect(() => {
    const currentInputValue = quizQuestions[currentQuestionViewIndex].children[childIndex].inputValue;
    setLastSearchText(currentInputValue)
    setInputValue(currentInputValue);
  }, [quizQuestions])



  function filterInputValue(text, fromInventory = false) {
    setQuizQuestions(
      produce((quizQuestionsDraft) => {
        const currentQuestionDraft = quizQuestionsDraft[currentQuestionViewIndex].children[childIndex];
        currentQuestionDraft.inputValue =  text;
        currentQuestionDraft.inputFromInventory = fromInventory;
    }))
    if (fromInventory) {
      closeDropdown();
    } else {
      openDropdown();
    }
  }


  return (
    <div className="quiz-input-container" >
      <FormFieldWithLabel
          label= {quizQuestions[currentQuestionViewIndex].children[childIndex].inputLabel}
          type= "text"
          id= {quizQuestions[currentQuestionViewIndex].children[childIndex].inputLabel}
          placeholder=""
          value={inputValue}
          onChange={(e) => {
            filterInputValue(e.target.value, false);
          }}
          onFocus ={openDropdown}
          onBlur={closeDropdown}
          errorMessage={""}
        />
        {inventoryListShow && !isRequestPending && filteredInventoryList?.length > 0  && <QuizInputList filteredInventoryList={filteredInventoryList}  editAndFilterInputValue={filterInputValue} /> }
    </div>
  );
}
