import { ANR_ID, CCF_ID } from "../constants/dealershipIds"

export const CCF_AGED_INVENTORY = [
'W7L02629',
'W5L15376',
'W5L12377',
'W3L59605',
'W3L36914',
'W3L08953',
'W3L03625',
'W3L03553',
'W3L03453',
'W3L03111',
'W3L02694',
'W3L02546',
'W3L02469',
'W3L02398',
'W3L01605',
'W3B16840',
'W3B15714',
'W2L49377',
'W2L49241',
'W2L19301',
'W2L17814',
'W1L14935',
'W1E52317',
'W1E44096',
'W1E09206',
'W1E07749',
'U9N94680',
'U9N06942',
'U9N02690',
'U0E47120',
'U0E46685',
'U0E15289',
'R9D90108',
'R9C55600',
'R9C07934',
'R9B16631',
'R4L12884',
'R4L07960',
'K4S52747',
'K4S42604',
'K4K61344',
'K2A72112',
'K1R00746',
'E9B35162',
'E9A35441',
'E2B35722',
'E1A34773',
'8T105004',
'8R502267',
'8F150000'
]

export const ANR_AGED_INVENTORY = [
    '1N6ED1EK2RN675713',
    'JN8BT3DD9NW276930',
    '5N1AL1FS5SC335360',
    '2HGFC2F50LH008830',
    '5N1AL1HU2SC338988',
    'JN8AZ3DE3S9700065',
    'JN8AZ3CC1S9601938',
    '5N1AZ2CS7RC101689',
    'JN8BT3CB6SW413477',
    '5YFBPMBE6MP242484',
    '3N1AB8CV2RY337649',
    'JN1BZ4BH3RM363491',
    '3N1CP5CVXML557524',
    'JM1CW2CL7G0191763',
    '3N8AP6BA5SL316657',
    'JTDBDMHE9SJ011886',
    '1V26NPE81PC050139',
    'KMHLR4AF8PU583218',
    'JN1AF0BA0PM406309',
    '3VW2K7AJ0DM371078',
    'W1KCG4EB0RA047472',
    '5TDGSKFC0RS143837',
    'JN1BJ1AW8MW427530',
    '2GKFLSEK6G6120804',
    'JN8AZ3DE8S9700031',
    '5N1AL1FS7SC330631',
    '3N1AB8DVXRY250967',
    'JN8BT3CB1RW291119',
    'KNDJP3AE2J7034431',
    'JTNK4MBE3P3207340',
    '4JGDA5GB1GA701116',
    '5N1DR3AC7NC249270',
    'JN8AZ1MU6DW207951',
    '1N6AA1EJ8HN519849',
    '2HKRW2H23KH146731',
    '3N1CP5DV1RL497946',
    '3PCAJ5BB2MF121424',
    'KMHC85LHXKU048486',
    'JN1EV7CR3PM543519',
    'JN1EV7BR7PM543136',
    '3N8AP6BA2SL305826',
    'JN1BZ4AH9RM364050',
    '3N8AP6BB8SL315253',
    '5N1DL1ESXRC350512',
    'JN1DF0CD9RM738631',
    '5N1DR3CE9RC257990',
    '3N1AB8DV5RY256546',
    '5N1DL1FS8RC351351',
    '3PCAJ5LR4RF108290',
    '3N1AB8DV7RY266446',
    '5N1AZ2CS8RC111826',
    '5N1DR3CE0RC249003',
    'JN1BZ4AHXRM361724',
    '5N1DR3CE6RC249894',
    'JN1EV7CR0RM631382',
    '5N1AZ2CS4RC110804',
    'JTHP9JBH3L2028251',
    'JTJHKCFZ4S2044723',
    '5N1AZ2CS4RC112715',
    '5FNRL6H6XLB503809',
    'ML32A3HJ6JH002040',
    'JN8BT3AB8SW414472',
    'JN8BT3BB4SW147202',
    '3N1CP5CV7SL464750',
    '3N1CP5CVXSL465178',
    'JN8BT3BB4SW144221',
    'JN8BT3BB4SW143943',
    'JN8BT3DD2SW471313',
    'JN8BT3DD1SW471240',
    '5N1AL1ESXSC338692',
    'JN8BT3BBXSW146314',
    'JN8BT3BB2SW145514',
    'JN8BT3ABXSW412898',
    'JN8BT3BB9SW145462',
    'JN8BT3AB2SW412734',
    'JN8BT3AB7SW413779',
    'JN8BT3BBXSW145275',
    'JN8BT3DD3SW471126',
    'JN8BT3BB4SW146681',
    'JN8BT3BB5SW145264',
    'JN8BT3BB6SW145841',
    'JN8BT3BB1SW145830',
    '3N1CP5BV1SL465068',
    '1N6ED1EK0SN613152',
    'JN1BZ4BH2RM365698',
    'JN8BT3CB7SW146267',
    'JN8BT3CB7SW146785',
    'JN8BT3BB6SW145824',
    'JN8BT3CB9SW146187',
    'JN8BT3BB0SW145639',
    'JN8BT3BB3SW145795',
    'JN8BT3BBXSW145776',
    'JN8BT3BB8SW144772',
    'JN8BT3AB1SW413213',
    '3N1CP5CV6SL465744',
    'JN8BT3AB5SW414462',
    'JN8BT3DD4SW300904',
    'JN8BT3CB0SW149639',
    'JN1AJ0HR5AM751435',
    '5UXTY5C08L9B34299',
    'JN8BT3BB3SW144274',
    '5N1AL1FSXSC330543',
    'JN8BT3AB1SW414779',
    '3N1AB8DV8RY370556',
    '3N1AB8DV7RY350430',
    '5N1DL1FS2RC353905',
    '5TDBBRCH0LS511010',
    'JM3TCBCY9K0305587',
    '5N1AL1FS2SC332447',
    '3PCAJ5FB0RF113551',
    'JN1EV7ARXRM630016',
    '3N1AB8DVXRY368002',
    'JN1CF0BB0RM739419',
    '5TDAAAB54RS019404',
    'JN8AZ3DE6S9700223',
    '5N1AR2MM6GC649129',
    '5N1DR3CE9RC276975',
    '5N1DR3AC1RC273943',
    '3PCAJ5FB3RF112457',
    'JN1CF0BB9RM738723',
    '5N1AL1GS6SC333406',
    'JN8AZ3DE0S9700640',
    '3PCAJ5KR4SF100925',
    '2HGFE2F22NH119642',
    '5N1DL0MM8LC500346',
    '5N1DL1ES0RC358277',
    '5N1AL1FS4SC331686',
    'JN1FV7DR7RM680016',
    '3N1CP5BV3SL464682',
    'JN8AZ3DE6S9700514',
    '3PCAJ5FB1RF114059',
    'JN1BZ4BH9RM364659',
    'JN8BT3AB5SW413795',
    'JN8BT3AB5SW411688',
    '1N4CZ1CV0SC562083',
    'WAUC4AF46JA002796',
    'JN1EV7CR7RM630665',
    '1N6ED1FK5SN600315',
    '5N1DL1FS1RC356083',
    '3N1AB8DVXRY266991',
    '5N1AL1HUXSC336471',
    '5N1DL1FS1RC358464',
    '3N1AB8DV1RY366199',
    '5N1DL1FS1RC353118',
    '5N1AL1HU3SC330236',
    '5TDGRKEC2NS109941',
    'JN1FV7DR0RM680164',
    '5N1AZ2BSXRC125826',
    'JN8AZ3DE3S9701510',
    '5N1AL1FS2SC334487',
    '3N1CP5BV2SL465354',
    '3N8AP6BA6SL310978',
    'JN1BZ4BHXRM363391',
    '5N1AL1HU8SC335559',
    '3PCAJ5LR9SF100949',
    '5N1AL1FS7SC332847',
    '5N1AL1FS3SC330626',
    'JN1BZ4BH7RM364725',
    '3PCAJ5EB4RF108001',
    'JN8BT3DD1SW470623',
    '5N1DR3ACXRC280258',
    'JN8BT3BBXSW144238',
    '5N1AL1ES5SC331245',
    'JN8BT3DD0SW470628',
    'JN8BT3DD9SW470806',
    'JN8BT3AB7SW413300',
    'JN8BT3BB6SW411777',
    '5N1AL1FS7SC337434',
    'JN8BT3BB4SW413351',
    'JN8BT3DD6SW300421',
    'JN8BT3DD2SW300481',
    '5N1AL1GS4SC335557',
    '5N1AL1ES5SC331357',
    '5N1AL1ES4SC335870',
    '5N1AL1FS0SC335976']

export const AGED_INVENTORY = {
    [CCF_ID]: {
        "list": CCF_AGED_INVENTORY,
        "mappingKey":"stockNumber"
    },
    [ANR_ID]: {
        "list": ANR_AGED_INVENTORY,
        "mappingKey": "vin"
    }
}