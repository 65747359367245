import "./QuizOptionCard.scss";
import { useEffect, useState } from "react";
import React from "react";
import { produce } from "immer";
import { useDealershipContext } from "../../contexts/dealershipContext";
import QIInventoryListInput from "../QIInventoryListInput/QIInventoryListInput";
import QIConditionalInput from "../QIConditionInput/QIConditionalInput";
import { INVENTORY_SEARCH_QUIZ_INTERFACE } from "../../constants/quizInterfaces";


export function SelectInputContainer( 
  { quizQuestions,
    setQuizQuestions,
    currentQuestionViewIndex,
    childIndex, }) {

   switch(quizQuestions[currentQuestionViewIndex]?.children[childIndex].quizInterface) {

    case INVENTORY_SEARCH_QUIZ_INTERFACE : return (
       <QIInventoryListInput
       currentQuestionViewIndex={currentQuestionViewIndex}
        childIndex={childIndex}
        quizQuestions={quizQuestions}
        setQuizQuestions={setQuizQuestions}
      />
    )

    default : return (
      <QIConditionalInput
        currentQuestionViewIndex={currentQuestionViewIndex}
        childIndex={childIndex}
        quizQuestions={quizQuestions}
        setQuizQuestions={setQuizQuestions}
      />
      )
   }
}



export function QuizOptionCard({
  text,
  greyicon,
  subtext,
  selectOption,
  unselectOption,
  selected,
  className = "card__unselected",
  index,
}) {
  const [isSelected, setIsSelected] = useState(selected);

  const handleSelect = () => {
    if (isSelected) {
      unselectOption(index);
    } else {
      selectOption(index);
    }
    setIsSelected(!isSelected);
  };

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  // conditional import for the image to avoid breaking if greyicon is not provided
  const iconSrc = greyicon ? require(`../../assets/icons/${greyicon}`) : "";

  return (
    <div
      className={`card ${className === "big-image" ? "card__wider" : ""} ${
        isSelected ? "card__selected" : "card__unselected"
      } ${subtext === "" ? "" : "card__long"} ${className}`}
      onClick={handleSelect}
    >
      {greyicon && (
        <div
          className={`card__images ${
            className === "feature-card" ? "feature-card__images" : ""
          } ${className === "feature" ? "feature-card__images" : ""}`}
        >
          <img
            className={`card__images--icon ${
              className === "feature-card"
                ? "feature-card__images--icon"
                : className === "big-image"
                  ? "card__images--big-image"
                  : className === "feature"
                    ? "feature-card__images--icon"
                    : ""
            }`}
            src={iconSrc}
            alt=""
          />
        </div>
      )}
      <div
        className={`card--text ${
          className === "feature-card" ? "feature-card__text" : ""
        }`}
      >
        {text}
      </div>
      <div className="card--subtext">{subtext}</div>
    </div>
  );
}

export function QuizOptionCardContainer({
  quizQuestions,
  setQuizQuestions,
  currentQuestionViewIndex,
  childIndex,
}) {

  const {dealershipId} = useDealershipContext();

  const selectOption = (optionIndex) => {
    setQuizQuestions(
      produce((quizQuestionsDraft) => {
        const selectedOptions =
          quizQuestionsDraft[currentQuestionViewIndex].children[childIndex]
            .currSelected;
        const maxOptionLen =
          quizQuestionsDraft[currentQuestionViewIndex].children[childIndex]
            .selectionRange.maximumSelection;
        const selectedOptionsIndex = selectedOptions.indexOf(optionIndex);
        if (selectedOptions.length >= maxOptionLen) {
          const removedOptionInfo = selectedOptions.shift();
          quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].options[removedOptionInfo].selected = false;
        }
        if (
          selectedOptionsIndex === -1 &&
          selectedOptions.length < maxOptionLen
        ) {
          selectedOptions.push(optionIndex);
          quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].options[optionIndex].selected = true;
        }
        return quizQuestionsDraft;
      })
    );
  };

  const unselectOption = (optionIndex) => {
    setQuizQuestions(
      produce((quizQuestionsDraft) => {
        const selectedOptions =
          quizQuestionsDraft[currentQuestionViewIndex].children[childIndex]
            .currSelected;
        const selectedOptionsIndex = selectedOptions.indexOf(optionIndex);
        if (selectedOptionsIndex !== -1) {
          quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].options[optionIndex].selected = false;
          quizQuestionsDraft[currentQuestionViewIndex].children[childIndex].currSelected.splice(selectedOptionsIndex, 1);
        }
      })
    );
  };

  return (
    <>
      {quizQuestions[currentQuestionViewIndex].children[childIndex].options.map((option, index) => (
        <QuizOptionCard
          key={currentQuestionViewIndex * 100 + childIndex * 10 + index}
          index={index}
          text={option.text}
          subtext={option.subtext}
          greyicon={option.greyicon}
          selectOption={selectOption}
          unselectOption={unselectOption}
          selected={option.selected}
          className={quizQuestions[currentQuestionViewIndex].children[childIndex].optionStyle}
        />
      ))}

      {/* if it's an conditional input question */}
      {quizQuestions[currentQuestionViewIndex].children[childIndex]?.inputCondition?.call(quizQuestions) === true && (
        <SelectInputContainer 
          dealershipId = {dealershipId}
          currentQuestionViewIndex={currentQuestionViewIndex}
          childIndex={childIndex}
          quizQuestions={quizQuestions}
          setQuizQuestions={setQuizQuestions}
        />
      )}
    </>
  );
}
