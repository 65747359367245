export function collectResponsesSeparate() {
    return this.children.map((childQuestion,_) => {
        return {
            question : this.children.length <= 1 ? this.viewText : childQuestion.text,
            selectedOptions : childQuestion.options?.filter((opt) => opt.selected).map((opt) => opt.text.replace(/^[^a-zA-Z0-9]+/, '')
        ) || [],
            ...(childQuestion.inputCondition ? {inputValue : childQuestion.inputValue} : {})
        }
    })
}

export function collectResponsesAggregated() {
    return {
        question : this.viewText,
        selectedOptions : this.children.flatMap((childQuestion,_) => {
            return childQuestion.options?.filter((opt) => opt.selected).map((opt) =>  opt.text.replace(/^[^a-zA-Z0-9]+/, '')) || []
        })
    }
}