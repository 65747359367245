export function allChildrenAnswered(currentQuestionViewIndex) {
    return this[currentQuestionViewIndex].children.every((childQuestion, childIndex) => {
      return childQuestion.isAnswered.call(this, currentQuestionViewIndex, childIndex);
    })
}

export function oneChildrenAnswered(currentQuestionViewIndex) {
    return this[currentQuestionViewIndex].children.some((childQuestion , childIndex) => {
        return childQuestion.isAnswered.call(this,currentQuestionViewIndex, childIndex);
    })
}