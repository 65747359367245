import { useState, useEffect } from "react";
import { fetchDealershipInventoryList } from "../services/dealership-inventory";

export function useFetchInventoryList(dealershipId, filteredInventoryList, setFilteredInventoryList) {
    const [inventoryList , setInventoryList] = useState([]);
    const [requestPending , setRequestPending] = useState(true);

    useEffect(() => {
        const getInventoryList = async () => {
          console.log("Request Sent");
          const { inventoryList } = await fetchDealershipInventoryList(dealershipId);
          console.log(inventoryList , "inventoryList");
          if(filteredInventoryList?.length === 0) setFilteredInventoryList(inventoryList);
          setInventoryList(inventoryList);
          setRequestPending(false);
        }  
        getInventoryList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dealershipId])
 
      return {
        inventoryList,
        requestPending
    }
}